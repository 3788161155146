<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-07 23:03:10
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-22 14:39:15
-->
<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onAdd" round icon="plus">新增</el-button>
  </el-card>

  <el-card class="box data">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="item.name" :name="item.app_key" v-for="item in apps" :key="item.id"></el-tab-pane>
    </el-tabs>

    <el-table :data="functions" style="width: 100%" v-loading="loading" row-key="id" border>
      <!-- <el-table-column label="编号" prop="id" width="80"></el-table-column> -->
      <!-- <el-table-column label="全称" prop="full_name" width="260"></el-table-column> -->
      <el-table-column label="名称" prop="name" width="200"></el-table-column>
      <el-table-column label="唯一编码" prop="code" min-width="240"></el-table-column>
      <el-table-column label="序号" prop="sequence" width="80"></el-table-column>
      <el-table-column label="vue url" prop="url" width="200"></el-table-column>
      <el-table-column label="vue redirect" prop="redirect" width="200"></el-table-column>
      <el-table-column label="component" prop="component" width="200"></el-table-column>
      <el-table-column label="vue icon" prop="icon_class" width="150"></el-table-column>
      <el-table-column label="状态" prop="is_stop" width="100">
        <template #default="scope">
          <el-tag :type="scope.row.is_stop ? 'danger' : 'success'">
            {{
            scope.row.is_stop ? "已停止" : "正常"
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180">
        <template #header>
          <el-button
            size="small"
            round
            icon="Tickets"
            type="info"
            @click="openDraggable(functions)"
          >排序</el-button>
        </template>
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)">修改</el-button>
          <el-popconfirm title="确定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger">删除</el-button>
            </template>
          </el-popconfirm>
          <el-button
            size="small"
            link
            round
            type="info"
            @click="openDraggable(scope.row.children)"
          >子项排序</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-function :item="current" :apps="apps" @success="reload" :is_menu="true"></edit-function>

  <el-dialog
    title="排序"
    v-model="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <draggable item-key="id" v-model="drag_list">
        <template #item="{ element , index }">
          <div class="draggable_row">{{index+1}}、{{element .name}}</div>
        </template>
      </draggable>
    </div>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确 定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import Draggable from "vuedraggable";
import app_api from "@/http/app_api";
import function_api from "@/http/function_api";
import EditFunction from "./edit_function.vue";
export default {
  components: {
    EditFunction,
    Draggable,
  },
  data() {
    return {
      loading: true,
      activeName: "",
      apps: [],
      functions: [],
      current: null,
      //排序弹框
      dialogVisible: false,
      //待排序的列表
      drag_list: [],
      saving: false,
    };
  },

  created() {
    /**
     * 加载全部的应用
     */
    app_api.get_apps().then((res) => {
      if (res.code == 0) {
        if (res.data.length > 0) {
          this.apps = res.data;
          var first = res.data[0];
          this.activeName = first.app_key;
          this.app_key = first.app_key;
          this.loadData();
        }
      }
    });
  },
  methods: {
    /**
     * 加载菜单数据
     */
    loadData() {
      this.loading = true;
      function_api
        .get_tree_funs(this.app_key)
        .then((res) => {
          if (res.code == 0) {
            this.functions = res.data.filter((x) => x.is_menu);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * tab切换
     */
    handleClick(ctx) {
      this.loading = true;
      this.app_key = ctx.props.name;
      this.loadData();
    },
    /**
     * 新增
     */
    onAdd() {
      this.current = {};
    },
    /**
     * 修改
     */
    onEdit(item) {
      var obj = Object.assign({}, item);
      delete obj.children;
      this.current = obj;
    },
    /**
     * 编辑回调重新加载
     */
    reload() {
      this.current = null;
      this.loadData();
    },
    /**
     * 删除
     */
    onDelete(item) {
      this.$http.get("/admin/v1/function/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.loadData();
        }
      });
    },

    /**
     * 打开弹出排序框
     */
    openDraggable(list) {
      this.drag_list = list.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      this.dialogVisible = true;
    },

    /**
     * 确定提交排序
     */
    onSubmit() {
      // this.saving=true;
      console.log(this.drag_list);
      this.$http
        .post(
          "admin/v1/function/sort?appKey=" + this.app_key,
          this.drag_list.map((x) => x.id)
        )
        .then((res) => {
          if (res.code == 0) {
            this.dialogVisible = false;
            this.loadData();
          }
        });
    },
  },
};
</script>


<style scoped>
.draggable_row {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--el-border-color);
}
</style>